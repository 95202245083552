import React from "react";
//import Error from "../error/error";
//import { FaRegPlayCircle } from "react-icons/fa";
import Grid from "@mui/material/Unstable_Grid2";
import "./orderInfo.css";
import { useQuery, gql } from "@apollo/client";
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from "react-router-dom";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { Card, CardContent, CardHeader } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";

import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";
import moment from "moment";

/*
const UPDATE_ORDER = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;
*/

const GET_ORDER = gql`
  query getTextGenerationOrder($id: ID!) {
    getTextGenerationOrder(id: $id) {
      id
      address
      headline
      normaltext
      someText
      areaText
      imageUrls
      highlightText
      createdAt
      profile {
        id
        name
      }
      texts {
        id
        text
        section {
          id
          name
          externalRef
          sorting
          createdAt
        }
      } 
    }
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const OrderInfo: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  //const [openCopyToClipboardInfo, setOpenCopyToClipboardInfo] = React.useState(false);
  //const [openSavedInfo, setOpenSavedInfo] = React.useState(false);
  //const [openNoteSavedInfo, setOpenNoteSavedInfo] = React.useState(false);
  //const [openErrorInfo, setOpenErrorInfo] = React.useState(false);
  //const [currentNoteText, setCurrentNoteText] = React.useState("");
  const [tabSelected, setTabSelected] = React.useState(0);
  const [openImageViewer, setOpenImageViewer] = React.useState(false);
  const [SelectedImage, setSelectedImage] = React.useState(0);
  //const [updateOrder] = useMutation(UPDATE_ORDER);
  const thumbnailsRef = React.useRef(null);

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: {
      id: id
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    //return <Error errorCode={500} />;
  }

  if (!data) {
    //return (<Error errorCode={500} />);
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  }


  /*
    const addLinebreaks = (text: String) => {
      return text.replaceAll("\\n", "\n");
    };
  */
  //map media to slides
  const slides = data.getTextGenerationOrder.imageUrls.map((imageUrl: any) => {

    return { src: imageUrl.split("?")[0] + "?width=800", SrcSet: [{ src: imageUrl.split("?")[0] + "?width=200" }], type: "image" };

  });

  //sort text by section

  let onsortedTexts = [...data.getTextGenerationOrder.texts];

  //remove text with no section
  onsortedTexts = onsortedTexts.filter((text: any) => {
    return text.section !== null;
  });

  const texts = onsortedTexts.sort((a: any, b: any) => {
    if (b.section.sorting > 0) {
      if (a.section.sorting < b.section.sorting) {
        return -1;
      }
      if (a.section.sorting > b.section.sorting) {
        return 1;
      }
      return 0;
    } else {
      if (a.section.createdAt < b.section.createdAt) {
        return -1;
      }
      if (a.section.createdAt > b.section.createdAt) {
        return 1;
      }
      return 0;
    }
  });

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className="orderInfo MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.OrderList} view={viewType.EstateAgent} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Genereret tekst</div>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Info'} />
                    <CardContent>
                      <div>
                        <div className="item">
                          <div className="itemLabel">Addresse:</div>
                          <div className="itemData">{data.getTextGenerationOrder.address}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Fremhævet:</div>
                          <div className="itemData">{data.getTextGenerationOrder.highlightText}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Billeder:</div>
                          <div className="itemData itemImage" >{data.getTextGenerationOrder.imageUrls.length}

                            <div onClick={() => { setSelectedImage(0); setOpenImageViewer(true) }} style={{ cursor: 'pointer' }}>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Interface / External_Link">
                                  <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">Genereringsprofil:</div>
                          <div className="itemData itemImage">{data.getTextGenerationOrder.profile.name}
                            <div onClick={() => { navigate('/profile/' + data.getTextGenerationOrder.profile.id); }} style={{ cursor: 'pointer' }}>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Interface / External_Link">
                                  <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                              </svg>
                            </div>

                          </div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">Genereret d.:</div>
                          <div className="itemData">{moment(data.getTextGenerationOrder.createdAt).format("DD-MM-YYYY")} {String(new Date(data.getTextGenerationOrder.createdAt).getHours()).padStart(2, '0') + ":" + String(new Date(data.getTextGenerationOrder.createdAt).getMinutes()).padStart(2, '0')}</div>
                        </div>
                      </div>



                    </CardContent>
                  </Card>

                </Paper>
              </Grid>

              <Grid xs={12}>
                <Paper elevation={3} >
                  <Card>
                    <CardHeader title={'Tekster'} />
                    <CardContent>
                      <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs value={tabSelected} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Profil tekster" {...a11yProps(0)} />
                            <Tab label="Rå tekster" {...a11yProps(1)} />
                          </Tabs>
                        </Box>

                        <CustomTabPanel value={tabSelected} index={0}>
                          <div>
                            {texts.map((text: any, index: number) => {
                              return (

                                <div key={index} className="item">
                                  <div className="itemLabel">{text.section.name}:</div>
                                  <div className="itemData" dangerouslySetInnerHTML={{ __html: text.text.replaceAll('\n', '<br/>') }}></div>
                                </div>
                              )

                            })
                            }
                          </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabSelected} index={1}>
                          <div>
                            <div className="item">
                              <div className="itemLabel">Overskrift:</div>
                              <div className="itemData" dangerouslySetInnerHTML={{ __html: data.getTextGenerationOrder.headline.replaceAll('\n', '<br/>') }}></div>
                            </div>
                            <div className="item">
                              <div className="itemLabel">Normal tekst:</div>
                              <div className="itemData" dangerouslySetInnerHTML={{ __html: data.getTextGenerationOrder.normaltext.replaceAll('\n', '<br/>') }}></div>
                            </div>
                            {data.getTextGenerationOrder.areaText.length > 0 && (
                              <div className="item">
                                <div className="itemLabel">Område tekst:</div>
                                <div className="itemData" dangerouslySetInnerHTML={{ __html: data.getTextGenerationOrder.areaText.replaceAll('\n', '<br/>') }}></div>
                              </div>
                            )}
                            <div className="item">
                              <div className="itemLabel">SoMe tekst:</div>
                              <div className="itemData" dangerouslySetInnerHTML={{ __html: data.getTextGenerationOrder.someText.replaceAll('\n', '<br/>') }}></div>
                            </div>
                          </div>
                        </CustomTabPanel>
                      </>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>



              {/* Images and videos */}
              {data.getTextGenerationOrder.imageUrls.length > 0 && (
                <Grid xs={12}>
                  <Paper elevation={3} >
                    <Card>
                      <CardHeader title={'Media'} />
                      <CardContent>
                        <div>
                          <div className="mediaContainer">
                            {data.getTextGenerationOrder.imageUrls.map((imageUrl: any, index: number) => {
                              return (
                                <div key={index} className="mediaItem" onClick={() => { setSelectedImage(index); setOpenImageViewer(true) }}>
                                  <img src={imageUrl.split("?")[0] + "?width=200"} alt="" />
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              )}



            </Grid>
          </div>
        </Grid>
      </Grid>



      <Lightbox
        styles={{ thumbnailsContainer: { backgroundColor: "#353535" } }}
        plugins={[Thumbnails, Counter, Video]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        thumbnails={{ ref: thumbnailsRef }}
        open={openImageViewer}
        close={() => setOpenImageViewer(false)}
        slides={slides}
        index={SelectedImage}
      />
    </div>
  );
};

export default OrderInfo;
